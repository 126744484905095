c
<template>
  <div class="pageContol formCom">
    <div class="framePage">
      <div class="framePage-title">
        <span>
          <em>当前位置：</em>
          <a href="javascript:;">审核管理</a>
          <i>></i>
          <a href="javascript:;" @click="goHerf">直播班级审核</a>
          <i>></i>
          <a href="javascript:;" @click="init" class="cur-a">详情</a>
        </span>
      </div>
      <div class="framePage-body">
        <div class="framePage-scroll">
          <div class="ovy-a">
            <h3 style="padding-bottom: 20px">班级信息</h3>
            <div>
              <el-form :inline="false" label-width="10rem" class="formCell">
                <el-form-item label="班级名称：">
                  <p>{{ Form.liveProjectName }}</p>
                </el-form-item>
                <el-form-item label="所属期次：">
                  <p>{{ Form.periodName }}</p>
                </el-form-item>
                <el-form-item label="有效日期：">
                  <p>
                    {{ Form.startDate | momentDate }} -
                    {{ Form.endDate | momentDate }}
                  </p>
                </el-form-item>
                <el-form-item label="行政区划：">
                  <p>{{ Form.areaName }}</p>
                </el-form-item>
                <el-form-item label="负责人：">
                  <p>{{ Form.projectUser }}</p>
                </el-form-item>
                <el-form-item label="负责人电话：">
                  <p>{{ Form.projectUserPhone }}</p>
                </el-form-item>
                <el-form-item label="培训类型：">
                  <p>{{ Form.Trainingtype }}</p>
                </el-form-item>
                <el-form-item label="培训人数：">
                  <p>{{ Form.projectPeople }}</p>
                </el-form-item>
              </el-form>
            </div>
            <el-divider></el-divider>
            <h3 style="padding-bottom: 20px">直播课程</h3>
            <el-table
              ref="multipleTable"
              :data="studentList"
              size="small"
              tooltip-effect="dark"
              :header-cell-style="tableHeader"
            >
              <el-table-column
                label="序号"
                align="center"
                type="index"
                :index="indexMethod"
              />
              <el-table-column
                label="直播课程名称"
                align="center"
                show-overflow-tooltip
                prop="liveCourseName"
                width="200"
              />
              <el-table-column
                label="直播课数量"
                align="center"
                show-overflow-tooltip
                prop="lessonNum"
              />
              <el-table-column
                label="关联班级"
                align="center"
                show-overflow-tooltip
                prop="liveProjectName"
              />
              <el-table-column
                label="开始时间"
                align="center"
                show-overflow-tooltip
                prop="startTime"
              >
                <template slot-scope="scope">
                  {{ scope.row.startTime | moment }}
                </template>
              </el-table-column>
              <el-table-column
                label="结束时间"
                align="center"
                show-overflow-tooltip
                prop="endTime"
              >
                <template slot-scope="scope">
                  {{ scope.row.endTime | moment }}
                </template>
              </el-table-column>
              <el-table-column label="操作" align="center" width="200">
                <template slot-scope="scope">
                  <el-button
                    style="padding: 0 5px"
                    type="text"
                    size="mini"
                    @click="see(scope.row.liveCourseId)"
                    >直播课节</el-button
                  >
                </template>
              </el-table-column>
              <Empty slot="empty" />
            </el-table>

            <br v-if="userJson.roleId == 2" /><br v-if="userJson.roleId == 2" />
            <div class="flexcb" v-if="userJson.roleId == 2">
              <h3 style="padding-bottom: 20px">点名设置</h3>
              <el-button size="mini" @click="onShow">点名设置</el-button>
            </div>
            <el-table
              v-if="userJson.roleId == 2"
              ref="multipleTable"
              :data="auditList"
              size="small"
              tooltip-effect="dark"
              :header-cell-style="tableHeader"
            >
              <el-table-column
                label="课节名称"
                align="center"
                :show-overflow-tooltip="true"
                prop="liveLessonName"
              />
              <el-table-column
                label="课节时长"
                align="center"
                :show-overflow-tooltip="true"
                prop="liveLessonNum"
              >
                <template slot-scope="scope">
                  {{ getTime(scope.row.liveLessonNum) }}
                </template>
              </el-table-column>
              <el-table-column
                label="点名次数"
                align="center"
                :show-overflow-tooltip="true"
                prop="dotNum"
              />
              <el-table-column
                label="点名间隔(分钟)"
                align="center"
                :show-overflow-tooltip="true"
                prop="space"
              />

              <el-table-column label="点名设置" align="center" width="480px">
                <div
                  slot-scope="scope"
                  style="display:flex;flex-direction: column"
                >
                  <div class="flexcc">
                    <span style="width: 80px">0分0秒</span>
                    <el-slider
                      style="width: 100%; margin: 0 15px; height: 50px"
                      v-model="scope.row.range"
                      :format-tooltip="tooltip"
                      :show-tooltip="showtooltip"
                      :disabled="true"
                      range
                      :min="0"
                      :max="scope.row.liveLessonNum"
                    ></el-slider>
                    <span style="min-width: 80px">{{
                      getTime(scope.row.liveLessonNum)
                    }}</span>
                  </div>

                  <p>
                    {{ getTime(scope.row.startDot) }}-{{
                      getTime(scope.row.endDot)
                    }}
                  </p>
                </div>
              </el-table-column>
              <Empty slot="empty" />
            </el-table>

            <br /><br />
            <h3 style="padding-bottom: 20px">审批记录</h3>
            <el-table
              ref="multipleTable"
              :data="ApprovalRecordList"
              size="small"
              tooltip-effect="dark"
              :header-cell-style="tableHeader"
            >
              <el-table-column
                label="操作时间"
                align="center"
                :show-overflow-tooltip="true"
                prop="createTime"
              />
              <el-table-column
                label="操作人员"
                align="center"
                :show-overflow-tooltip="true"
                prop="fullname"
              />
              <el-table-column
                label="操作内容"
                align="center"
                :show-overflow-tooltip="true"
                prop="auditState"
              >
                <template slot-scope="scope">
                  <span>{{ getAuditName(scope.row.auditState) }}</span>
                </template>
              </el-table-column>
              <el-table-column
                label="审批备注"
                align="center"
                :show-overflow-tooltip="true"
                prop="auditReason"
              />
              <Empty slot="empty" />
            </el-table>
            <div class="btn-box" v-if="auditName != 'jg'">
              <el-button class="bgc-bv" @click="goHerf">取 消</el-button>
              <el-button
                v-if="status != 'three' && currentAuditState != '40'"
                @click="projectReject"
                class="bgc-bv"
                :disabled="startStudy"
                >驳 回</el-button
              >
              <el-button
                v-if="status != 'second' && currentAuditState != '30'"
                class="bgc-bv"
                @click="projectPass"
                >通 过</el-button
              >
            </div>
            <div class="btn-box" v-else>
              <el-button class="bgc-bv" @click="goHerf">取 消</el-button>
              <el-button
                v-if="auditState != '30'"
                @click="projectReject"
                class="bgc-bv"
                :disabled="startStudy"
                >驳 回</el-button
              >
              <el-button
                v-if="auditState != '30'"
                class="bgc-bv"
                @click="projectPass"
                >通 过</el-button
              >
            </div>
          </div>
        </div>
      </div>
    </div>
    <el-dialog
      title="审核事由"
      :visible.sync="dialogVisible"
      width="30%"
      :before-close="handleClose"
      ><div>
        <el-input
          type="textarea"
          maxlength="100"
          show-word-limit
          placeholder="请输入审核理由"
          v-model="auditReason"
        ></el-input>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="cancle" class="bgc-bv">取 消</el-button>
        <el-button @click="sure()" class="bgc-bv">确 定</el-button>
      </span>
    </el-dialog>

    <!-- 点名设置 -->
    <el-dialog
      title="点名设置"
      :visible.sync="callTheRollShow"
      width="30%"
      :before-close="handleClose"
    >
      <div></div>
      <el-form
        :model="groupForm"
        style="width: 100%"
        :rules="rulesGroup"
        ref="groupForm"
      >
        <el-form-item label="点名次数：" label-width="100px" prop="dotNum">
          <div class="df">
            <el-input
              placeholder="点名次数"
              v-model="groupForm.dotNum"
            ></el-input>
            <span style="margin-left: 5px">次</span>
          </div>
        </el-form-item>
        <el-form-item label="时间间隔：" label-width="100px" prop="space">
          <div class="df">
            <el-input
              placeholder="时间间隔"
              v-model="groupForm.space"
            ></el-input>
            <span style="margin-left: 5px">分</span>
          </div>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="doCancle" class="bgc-bv">取 消</el-button>
        <el-button @click="save('groupForm')" class="bgc-bv">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import List from "@/mixins/List";
import moment from "moment";
export default {
  name: "ClassReviewDetails",
  mixins: [List],
  data() {
    return {
      // 驳回理由
      auditReason: "",
      liveProjectId: null,
      // 基本信息
      Form: {},
      //直播课程
      studentList: [],
      // 审批记录
      ApprovalRecordList: [],
      status: "first",
      dialogVisible: false,
      callTheRollShow: false,
      auditList: [],
      groupForm: {
        dotNum: "",
        space: "",
      },
      rulesGroup: {
        dotNum: [
          { required: true, message: "请输入点名次数", trigger: "blur" },
        ],
        space: [{ required: true, message: "请输入时间间隔", trigger: "blur" }],
      },
      auditName: "",
      auditState: "",
    };
  },
  mounted() {},
  created() {
    this.status = JSON.parse(this.$route.query.getClassInfo).status;
    this.auditName = JSON.parse(this.$route.query.getClassInfo).auditName;
    this.auditState = JSON.parse(this.$route.query.getClassInfo).auditState;
    console.log(this.auditName);
    this.liveProjectId = JSON.parse(
      this.$route.query.getClassInfo
    ).liveProjectId;
    this.essentialInformation();
    this.getStudentList();
    this.getDatas();
    this.getAuditPage();
    this.getNum();
  },
  computed: {
    ...mapGetters({
      userJson: "getUser",
    }),
  },
  methods: {
    // 显示点名设置弹窗
    onShow() {
      this.callTheRollShow = true;
      this.getNum();
    },

    // 审批记录 - 操作内容 - 处理列表字段
    getAuditName(value) {
      let AuditNameList = [
        {
          value: "10",
          label: "班级创建",
        },
        {
          value: "20",
          label: "提交审核",
        },
        {
          value: "30",
          label: "审核通过",
        },
        {
          value: "40",
          label: "审核驳回",
        },
      ];
      for (let i in AuditNameList) {
        if (AuditNameList[i].value == value) {
          return AuditNameList[i].label;
        }
      }
    },
    // 基本信息
    essentialInformation() {
      this.$post(
        "/liveProject/live-project/getLiveProject",
        {
          liveProjectId: this.liveProjectId,
        },
        3000,
        true,
        3
      )
        .then((res) => {
          this.Form = res.data;
          let name1 = "";
          let name2 = "";
          let name3 = "";
          let name4 = "";
          let name5 = "";
          if (res.data.trainTypeNamePath) {
            name1 = res.data.trainTypeNamePath;
          }
          if (res.data.postName) {
            name2 = "/" + res.data.postName;
          }
          if (res.data.industryNamePath) {
            name3 = "/" + res.data.industryNamePath;
          }
          if (res.data.occupationNamePath) {
            name4 = "/" + res.data.occupationNamePath;
          }
          if (res.data.trainLevelName) {
            name5 = "/" + res.data.trainLevelName;
          }
          let name = name1 + name2 + name3 + name4 + name5;
          name = name.replace(/,/g, "-");
          this.Form.Trainingtype = name;
        })
        .catch((err) => {
          return;
        });
    },
    // 直播课程 - 获取数据
    getStudentList() {
      this.$post(
        "/liveProject/live-project/queryCourseList",
        {
          liveProjectId: this.liveProjectId,
        },
        3000,
        true,
        3
      )
        .then((ret) => {
          this.studentList = ret.data;
        })
        .catch((err) => {
          return;
        });
    },
    // 跳转 - 直播课节
    see(liveCourseId) {
      this.$router.push({
        path: "/web/liveBroadcastManagement/liveClassCourseDetails",
        query: {
          liveCourseId,
          type: "zbbjsh",
        },
      });
    },
    // 审批记录
    getDatas() {
      this.$post(
        "/liveProject/live-project-audit/auditLogPageList",
        {
          auditObjectId: this.liveProjectId,
        },
        3000,
        true,
        3
      )
        .then((ret) => {
          if (ret.status == "0") {
            this.ApprovalRecordList = ret.data;
          }
        })
        .catch((err) => {
          return;
        });
    },
    // 通过
    projectPass() {
      if (this.auditName == "jg") {
        if (this.auditList.length == 0) {
          this.$message({
            message: "请先进行点名设置",
            type: "error",
          });
          return false;
        } else {
          this.$post(
            "/live/audit/supervise/pass",
            {
              liveProjectId: this.liveProjectId,
            },
            3000,
            true,
            3
          )
            .then((ret) => {
              if (ret.status == "0") {
                this.$message({
                  type: "success",
                  message: "成功",
                });
                this.goHerf();
                // this.$router.push({
                //   path: "/web/liveBroadcastManagement/liveClassReview",
                //   query: {
                //     active: this.status ? this.status : "second",
                //     refresh: true,
                //   },
                // });
              }
            })
            .catch((err) => {
              return;
            });
        }
      } else {
        this.$post(
          "/liveProject/live-project-audit/pass",
          {
            liveProjectId: this.liveProjectId,
          },
          3000,
          true,
          3
        )
          .then((ret) => {
            if (ret.status == "0") {
              this.$message({
                type: "success",
                message: "成功",
              });
              this.goHerf();
              // this.$router.push({
              //   path: "/web/liveBroadcastManagement/liveClassReview",
              //   query: {
              //     active: this.status ? this.status : "second",
              //     refresh: true,
              //   },
              // });
            }
          })
          .catch((err) => {
            return;
          });
      }
    },
    // 驳回 - 显示loading
    projectReject() {
      this.dialogVisible = true;
    },
    // 驳回
    sure() {
      if (!this.auditReason) {
        this.$message({
          message: "请输入驳回理由",
          type: "warning",
        });
        return false;
      }
      const parmar = {
        liveProjectId: this.liveProjectId,
        auditReason: this.auditReason,
        auditState: "40",
      };
      this.$post(
        this.auditName != "jg"
          ? "/liveProject/live-project-audit/reject"
          : "/live/audit/supervise/reject",
        parmar,
        3000,
        true,
        3
      )
        .then((ret) => {
          if (ret.status == 0) {
            this.$message({
              type: "success",
              message: "成功",
            });
            this.dialogVisible = false;
            this.goHerf();
          }
        })
        .catch((err) => {
          return;
        });
    },
    getTime(val) {
      if (!val) {
        return "0秒";
      }
      let h = parseInt(val / 60 / 60);
      let m = parseInt((val % 3600) / 60);
      let s = val % 60;
      if (h < 9) {
        h = "0" + h;
      }
      if (m < 9) {
        m = "0" + m;
      }
      if (s < 9) {
        s = "0" + s;
      }
      return (
        (h != "00" ? h + "时" : "") +
          (m != "00" ? m + "分" : "") +
          (s != "00" ? s + "秒" : "") || ""
      );
    },
    // 驳回 - 清空驳回字段
    cancle() {
      this.auditReason = "";
      this.dialogVisible = false;
    },
    // 点击取消
    back() {
      this.$router.push({
        path: "/web/liveBroadcastManagement/liveClassReview",
        query: {
          active: this.status ? this.status : "first",
          // name:
          //   this.status == "first"
          //     ? "first"
          //     : this.status == "second"
          //     ? "second"
          //     : "three"
        },
      });
    },
    goHerf() {
      if (this.auditName != "jg") {
        this.$router.push({
          path: "/web/liveBroadcastManagement/liveClassReview",
          query: {
            active: this.status ? this.status : "first",
            // name:
            //   this.status == "first"
            //     ? "first"
            //     : this.status == "second"
            //     ? "second"
            //     : "three"
          },
        });
      } else {
        this.$router.push({
          path: "/web/liveBroadcastManagement/liveClassToBeReviewedNew",
        });
      }
    },
    getAuditPage() {
      this.$post(
        "/live/audit/supervise/roll/page",
        { liveProjectId: this.liveProjectId },
        3000,
        true,
        3
      ).then((ret) => {
        ret.data.map((el) => {
          el.range = [el.startDot, el.endDot];
        });
        this.auditList = ret.data;
      });
    },
    getNum() {
      this.$post(
        "/live/audit/supervise/roll/parameter",
        { liveProjectId: this.liveProjectId },
        3000,
        true,
        3
      ).then((ret) => {
        this.groupForm = {
          ...ret.data,
        };
      });
    },

    // 点名设置保存
    save(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          const parmar = {
            liveProjectId: this.liveProjectId,
            liveProjectCourseId: this.studentList[0].liveProjectCourseId,
            dotNum: this.groupForm.dotNum,
            space: this.groupForm.space,
          };
          this.$post("/live/audit/supervise/roll/save", parmar, 3000, true, 3)
            .then((ret) => {
              if (ret.status == 0) {
                this.$message({
                  type: "success",
                  message: "成功",
                });

                this.callTheRollShow = false;
                this.getAuditPage();
              }
            })
            .catch((err) => {
              return;
            });
        } else {
          console.log(this.$refs[formName]);
          return false;
        }
      });
    },
    doCancle() {
      this.$refs["groupForm"].clearValidate();
      this.callTheRollShow = false;
    },
  },
};
</script>
<style lang="less">
.formCom {
  .formCell {
    display: flex;
    flex-wrap: wrap;
    .el-form-item {
      width: 50%;
    }
  }
  .chapterl-btns {
    .el-button {
      padding: 8px;
    }
  }
  .el-cascader {
    width: 100%;
  }
  .el-progress {
    width: 200px;
  }
  .chapterl-trees {
    padding: 15px 15px 15px 0;
    .el-tree-node__content {
      height: 36px;
    }
    .draggable-item {
      margin-left: 5px;
      em {
        margin-right: 5px;
      }
    }
    .trees-btns {
      .showbtn {
        color: #fff;
      }
      margin-left: 15px;
      & > em,
      .handle > em {
        color: #4089fa;
        font-size: 14px;
        cursor: pointer;
        &:not(:last-child) {
          margin-right: 5px;
        }
      }
    }
  }
  .chapteru-lists {
    padding: 15px 15px 15px 0;
    .el-divider--horizontal {
      margin: 5px;
    }
    .cl-subhint {
      display: flex;
      span {
        font-size: 14px;
        color: #666;
        &:nth-child(2) {
          margin-left: 15px;
        }
      }
      em {
        margin: 0 5px;
      }
    }
    .cl-btns {
      i {
        cursor: pointer;
        &:not(:last-child) {
          margin-right: 5px;
        }
      }
    }
  }
  .cl-img-box {
    .el-icon-receiving {
      font-size: 40px;
      color: #999;
      margin-right: 15px;
    }
  }
  .pageForm-btns {
    padding: 15px 0;
    text-align: center;
    .el-button {
      span {
        min-width: 4em;
      }
    }
  }
}
.img-el-upload {
  padding: 20px 0;
  min-height: 120px;
}
</style>
<style lang="less" scoped>
.btn-box {
  padding: 50px 0 10px;
  display: flex;
  justify-content: center;
  button {
    padding: 12px 30px;
  }
  .el-button.is-disabled,
  .el-button.is-disabled:focus,
  .el-button.is-disabled:hover {
    background: #a9a9a9;
    border-color: #a9a9a9;
    color: #fff;
  }
}
</style>
